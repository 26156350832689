// Ditching moment.js bring the need of a "fromNow" alternative method, self-implemented vanilla
// Inspired from https://gist.github.com/davidrleonard/259fe449b1ec13bf7d87cde567ca0fde

/**
 * Implements all the behaviors of moment.fromNow(). Pass a
 * valid JavaScript Date object and the method will return the
 * time that has passed since that date in a human-readable
 * format. Passes the moment test suite for `fromNow()`.
 * See: https://momentjs.com/docs/#/displaying/fromnow/
 *
 * @example
 *
 *     var pastDate = new Date('2017-10-01T02:30')
 *     var message = fromNow(pastDate) // fromNow(pastDate, true)
 *     //=> '2 days ago' // '2d'
 *
 * @param  {Date} Native JavaScript Date object
 * @return {string}
 */
export function fromNow (date, compact = false) {
  if (typeof date === 'string') date = new Date(date)
  let seconds = Math.round((new Date() - date) / 1000)
  let id = 'time.fromNow'
  // If seconds < 0, then it's a future date
  if (seconds < 0) {
    seconds = -seconds
    id = 'time.fromNowFuture'
  }
  const years = Math.round(seconds / 31536000)
  const months = Math.round(seconds / 2592000)
  const days = Math.round(seconds / 86400)
  const hours = Math.round(seconds / 3600)
  const minutes = Math.round(seconds / 60)

  if (days >= 365) return this.$t({ id }, { unit: 'years', nb: years, compact })
  else if (days >= 31 && days < 365) return this.$t({ id }, { unit: 'months', nb: months, compact })
  else if (days >= 1 && days < 31) return this.$t({ id }, { unit: 'days', nb: days, compact })
  else if (hours >= 1 && hours < 24) return this.$t({ id }, { unit: 'hours', nb: hours, compact })
  else if (minutes >= 1 && minutes < 60) return this.$t({ id }, { unit: 'minutes', nb: minutes, compact })
  else if (seconds >= 0 && seconds < 60) return this.$t({ id }, { unit: 'seconds', compact })
  else return this.$t({ id }, { unit: 'none', compact })
}

// Inspired from https://stackoverflow.com/a/25334277
export function manipulateTime ({ unit, nb, from = new Date() }) {
  let factor = 1000 // seconds
  if (unit === 'minutes') factor = 60000 // minutes
  else if (unit === 'hours') factor = 3600000 // hours
  else if (unit === 'days') factor = 86400000 // days
  else if (unit === 'months') factor = 2592000000 // months
  else if (unit === 'years') factor = 31104000000 // years
  return new Date(from.getTime() + nb * factor)
}

export function getIso (datetime = new Date()) {
  if (typeof datetime === 'string') datetime = new Date(datetime)
  return datetime.toISOString()
}

export function isoToMask (iso, mask) {
  // return (iso && mask) ? iso.substring('YYYY-MM-DDTHH:mm:ss.000Z'.indexOf(mask), mask.length) : null
  if (iso && mask) {
    const date = new Date(iso)
    return mask
      .replace('YYYY', date.getUTCFullYear())
      .replace('MM', String(date.getUTCMonth() + 1).padStart(2, '0'))
      .replace('DD', String(date.getUTCDate()).padStart(2, '0'))
      .replace('HH', String(date.getUTCHours()).padStart(2, '0'))
      .replace('mm', String(date.getUTCMinutes()).padStart(2, '0'))
      .replace('ss', String(date.getUTCSeconds()).padStart(2, '0'))
  } else return null
}

export function maskToIso (value, mask) {
  const yearIndex = mask.indexOf('YYYY')
  const monthIndex = mask.indexOf('MM')
  const dayIndex = mask.indexOf('DD')
  const hoursIndex = mask.indexOf('HH')
  const minutesIndex = mask.indexOf('mm')
  const secondsIndex = mask.indexOf('ss')

  const year = value.slice(yearIndex, yearIndex + 4)
  const month = value.slice(monthIndex, monthIndex + 2)
  const day = value.slice(dayIndex, dayIndex + 2)
  const hours = value.slice(hoursIndex, hoursIndex + 2)
  const minutes = value.slice(minutesIndex, minutesIndex + 2)
  const seconds = value.slice(secondsIndex, secondsIndex + 2)

  return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds)).toISOString()
}
